/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import { Box, Card, Flex, Image, Text } from 'theme-ui';
import hero from '../assets/vacant-hero.jpg';

import cloudinaryHelper from '../helpers/cloudinaryHelper';

const VacantSpaces = ({ data }) => {
  const vacantSpaces = data.businesses.items.filter(el =>
    el.categories.includes('Commercial property')
  );

  const sqrFootMap = {
    '132C Washington': '1,675',
    '136 Washington': '2,185',
    '127 Washington': '755',
    '135 Washington': '2,184',
    '132A Washington': '737',
    '119 Washington': '1,280',
    '123 Washington': '4,637',
    '68 Washington': '3,593',
    '99 Washington Street 2nd Floor': '6,600',
    '129 Washington Street': '880',
    '137 Washington Street': '1,000',
    '132B Washington Street': '749',
    '117 Washington Street': '800',
    '68 Washington St': '3,600 SF (Ground Floor) / 4,620 SF (Basement)',
    '128 Washington St': '5,368',
  };

  const getSquareFootage = name => sqrFootMap[name];

  return (
    <Layout pageTitle="Vacant Spaces">
      <Box
        sx={{
          height: '450px',
          background: `url(${cloudinaryHelper(
            'https://res.cloudinary.com/gonation/image/upload/v1704828342/sites/OnWashington/55-DJI_0145.jpg',
            2000
          )})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Text
          variant="heading"
          sx={{
            color: 'white',
            fontSize: 4,
            marginBottom: 4,
            textAlign: 'center',
            background: 'rgba(0,0,0,.8)',
            color: 'primary',
            padding: '2rem',
            borderBottom: '4px solid',
            borderColor: 'primary',
          }}
        >
          Open Your Business on Washington Street
        </Text>
      </Box>
      <Box sx={{ padding: [3, 2, 2] }}>
        <Flex sx={{ flexWrap: 'wrap' }}>
          {vacantSpaces.map(space => (
            <Link
              sx={{
                display: 'block',
                textDecoration: 'none',
                width: ['100%', '50%', '25%'],
                paddingY: 3,
                paddingX: [0, 1, 2],
              }}
              to={`/places/${space.slug}`}
            >
              <Box>
                <Card sx={{ border: '1px solid #eee' }}>
                  <Box>
                    <Image sx={{ height: '325px' }} src={space.cover}></Image>
                  </Box>
                  <Box sx={{ paddingX: 2, paddingY: 3 }}>
                    <Text
                      variant="heading"
                      sx={{
                        fontSize: 2,
                        fontWeight: 500,
                        mb: 2,
                        color: 'text',
                      }}
                    >
                      {space.name}
                    </Text>
                    <Text
                      as="p"
                      sx={{
                        fontFamily: 'lato',
                        fontSize: 1,
                        color: 'light',
                      }}
                    >
                      {getSquareFootage(space.name)} sq feet
                    </Text>
                  </Box>
                </Card>
              </Box>
            </Link>
          ))}
        </Flex>
        <Text sx={{ textAlign: 'center', mt: [2, 3, 4], mb: [2, 3, 4] }}>
          For Leasing Information: Nicholas Fiorillo (347)-926-3391
          Leasing@agwpartners.com
        </Text>
      </Box>
    </Layout>
  );
};

export default VacantSpaces;

export const query = graphql`
  {
    businesses {
      items {
        avatar
        categories
        categoryGroups {
          name
        }
        contact {
          facebook
          instagram
          phone
          url
        }
        cover
        description
        gonationId
        location {
          address {
            city
            country
            postalCode
            state
            street
          }
          coordinates {
            lat
            lng
          }
        }
        name
        slug
      }
    }
  }
`;
